import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { Manager, Target, Popper } from "react-popper";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import { useTranslation, Translation } from "react-i18next";
import { history } from "../../_helpers";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Language from "@material-ui/icons/Language";

// core components
import Button from "components/CustomButtons/Button.jsx";

import adminNavbarLinksStyle from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx";

function HeaderLinks(props) {
  const { i18n } = useTranslation();
  const changeLanguage = lng => {
    i18n.changeLanguage(lng);
    if (lng === "gr") {
      history.push("/rtl/typography");
    } else {
      history.push("/admin/typography");
    }
  };
  const [openLanguageSelector, setOpenLanguageSelector] = useState(false);

  const handleClick = () => {
    setOpenLanguageSelector({ openLanguageSelector: !openLanguageSelector });
  };

  const handleClose = () => {
    setOpenLanguageSelector(false);
  };

  const { classes, rtlActive } = props;

  const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover, {
    [classes.dropdownItemRTL]: rtlActive
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true
  });
  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <Button
          color="transparent"
          justIcon
          aria-label="Languages"
          aria-owns={openLanguageSelector ? "languages-list" : null}
          aria-haspopup="true"
          onClick={handleClick}
          className={rtlActive ? classes.buttonLinkRTL : classes.buttonLink}
          muiClasses={{
            label: rtlActive ? classes.labelRTL : ""
          }}
        >
          <Language
            className={
              classes.headerLinksSvg +
              " " +
              (rtlActive
                ? classes.links + " " + classes.linksRTL
                : classes.links)
            }
          />
        </Button>
        <Popper
          open
          transition
          disablePortal
          placement="bottom"
          className={classNames({
            [classes.popperClose]: !openLanguageSelector,
            [classes.pooperResponsive]: true,
            [classes.pooperNav]: true
          })}
        >
          {({ TransitionProps, placement }) => (
            <Paper className={classes.dropdown}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList role="menu">
                  <MenuItem
                    onClick={() => changeLanguage("gr")}
                    className={dropdownItem}
                  >
                    <Translation>
                      {(t, { i18n }) => <span>{t("lang_GR")}</span>}
                    </Translation>
                  </MenuItem>
                  <MenuItem
                    onClick={() => changeLanguage("en")}
                    className={dropdownItem}
                  >
                    <Translation>
                      {(t, { i18n }) => <span>{t("lang_EN")}</span>}
                    </Translation>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          )}
        </Popper>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(adminNavbarLinksStyle)(HeaderLinks);
